<template>
  <b-card>
    <b-card-title class="text-primary">Product Images</b-card-title>

    <!-- Scrollable Images Row -->
    <b-row v-if="imageSrcs.length > 0" class="scrolling-row" no-gutters>
      <b-col
        class="scrolling-col"
        v-for="(imageSrc, index) in imageSrcs"
        :key="index"
      >
        <MerchantProductImage
          :src="imageSrc"
          :enableDelete="false"
          :width="120"
          :height="120"
        />
      </b-col>
    </b-row>

    <!-- Else, No Images Text -->
    <p v-else>No images found for this product</p>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getGlobalProductImages } from "../../../apis/merchant";
import MerchantProductImage from "./MerchantProductImage";

export default {
  components: { MerchantProductImage },
  props: {
    globalProductId: {
      type: Number,
      required: true,
    },
  },
  created() {
    getGlobalProductImages(this.globalProductId, this.token).then((data) => {
      if (data) {
        const imageUrls = data.map((item) => {
          return item.productImage.blobUrl;
        });
        this.imageSrcs = [...imageUrls];
      }
    });
  },
  data() {
    return {
      imageSrcs: [],
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
};
</script>

<style scoped>
.scrolling-row {
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.scrolling-col {
  display: inline-block;
  float: none;
}
</style>

<template>
	<b-card header-text-variant="primary" header-class="text-center" header-tag="header">
		<template #header>
			<b-card-title>
				{{ isNew ? `Create Category` : `Edit Category` }}
			</b-card-title>
		</template>

		<!-- FORM -->
		<b-form>
			<!-- Category Name -->
			<b-form-group label="Category Name*" label-for="name" :invalid-feedback="getErrorMessage(errors, 'name')" :state="getErrorState(errors, 'name')">
				<b-form-input :state="getErrorState(errors, 'name')" type="text" id="name" v-model="form.name" placeholder="Category Name" trim />
			</b-form-group>

			<!-- Buttons -->
			<div class="text-center">
				<b-button class="mr-2" variant="primary" @click="onSubmit">
					{{ isNew ? "Create" : "Update" }}
				</b-button>
				<b-button @click="onCancel"> Cancel </b-button>
			</div>
		</b-form>
	</b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { addCategory, updateCategory } from "../../apis/merchant";
export default {
	props: {
		category: {
			type: Object,
		},
	},
	created() {
		this.form = { ...this.category };
	},
	data() {
		return {
			form: {
				name: null,
			},
			errors: null,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
		}),
		isNew() {
			if (this.category) {
				return false;
			}
			return true;
		},
	},
	methods: {
		...mapActions({
			addCategoryToState: "merchant/categories/addItem",
		}),
		onSubmit() {
			this.errors = null;
			if (this.isNew) {
				addCategory(this.form, this.token)
					.then((data) => {
						this.onSuccess(data);
					})
					.catch(({ data }) => {
						this.onErrors(data);
					});
			} else {
				updateCategory(this.category.id, this.form, this.token)
					.then((data) => {
						this.onSuccess(data);
					})
					.catch(({ data }) => {
						this.onErrors(data);
					});
			}
		},
		onSuccess(data) {
			this.addCategoryToState(data);
			this.$emit("success", data);
		},
		onErrors(data) {
			this.errors = data.errors;
		},
		onCancel() {
			this.$emit("cancel");
		},
	},
};
</script>

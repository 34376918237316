<template>
	<b-card header-text-variant="primary" header-class="text-center" header-tag="header">
		<template #header>
			<b-card-title>
				{{ isNew ? `Create Product` : `Update Product` }}
			</b-card-title>
		</template>

		<!-- PRODUCT IMAGES -->
		<div class="mb-3">
			<MerchantGlobalProductImages v-if="form.globalProductId" :globalProductId="form.globalProductId" />
			<MerchantProductImages v-else :product="product" @imageAdded="onImageAdded" @deleteImage="onDeleteImage" />
		</div>

		<!-- Title -->
		<b-card-title class="text-primary">
			{{ isNew ? `Create Product` : `Edit Product` }}
		</b-card-title>

		<!-- FORM -->
		<b-form>
			<!-- Product Name -->
			<b-form-group label="Product Name*" label-for="name" :invalid-feedback="getErrorMessage(errors, 'name')" :state="getErrorState(errors, 'name')">
				<b-form-input :state="getErrorState(errors, 'name')" type="text" id="name" v-model="form.name" placeholder="Product Name" trim />
			</b-form-group>

			<!-- Available -->
			<b-form-group label="List in store" label-for="available">
				<b-form-checkbox v-model="form.available" name="available">
					List
				</b-form-checkbox>
			</b-form-group>

			<!-- Selling Price -->
			<b-form-group label="Selling Price" label-for="price" :invalid-feedback="getErrorMessage(errors, 'price')" :state="getErrorState(errors, 'price')">
				<b-form-radio-group v-model="selectedPriceOption" :options="priceOptions" @change="onSelectOption" class="mb-3" />

				<b-form-input :state="getErrorState(errors, 'price')" id="price" v-if="selectedPriceOption" v-model="form.price" placeholder="Selling Price" @keypress="isNumber($event)" trim />
			</b-form-group>

			<!-- MRP -->
			<b-form-group v-if="selectedPriceOption" label="MRP" label-for="mrp" :invalid-feedback="getErrorMessage(errors, 'mrp')" :state="getErrorState(errors, 'mrp')" description="If MRP is not entered, the Selling Price will be used as the MRP">
				<b-form-input :state="getErrorState(errors, 'mrp')" id="mrp" v-model="form.mrp" placeholder="MRP" @keypress="isNumber($event)" trim />
			</b-form-group>

			<!-- Unit -->
			<b-form-group label="Select Unit*" label-for="unit" :invalid-feedback="getErrorMessage(errors, 'unit')" :state="getErrorState(errors, 'unit')">
				<b-form-select :state="getErrorState(errors, 'unit')" id="unit" v-model="form.unit" :options="unitOptions" />
			</b-form-group>

			<!-- Product Description -->
			<b-form-group label="Add Product Description" label-for="description" :invalid-feedback="getErrorMessage(errors, 'description')" :state="getErrorState(errors, 'description')">
				<b-form-textarea :state="getErrorState(errors, 'description')" id="description" v-model="form.description" placeholder="Product Description*" rows="3" max-rows="3" no-resize />
			</b-form-group>

			<!-- Categories -->
			<b-form-group label="Choose Category" label-for="categoryId" :invalid-feedback="getErrorMessage(errors, 'categoryId')" :state="getErrorState(errors, 'categoryId')">
				<b-input-group>
					<!-- Dropdown to select category -->
					<b-form-select :state="getErrorState(errors, 'categoryId')" id="categoryId" v-model="form.categoryId" :options="categories" value-field="id" text-field="name">
						<!-- This slot appears above the options from 'options' prop -->
						<template #first>
							<b-form-select-option :value="null">
								-- No Category --
							</b-form-select-option>
						</template>
					</b-form-select>

					<!-- Create Category Option -->
					<b-input-group-append v-if="!form.categoryId">
						<b-button @click.prevent="onCreateCategoryClicked" variant="outline-secondary">
							Create Category
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<!-- Buttons -->
			<div class="text-center">
				<b-button class="mr-2" variant="primary" @click="onSubmit">
					{{ isNew ? "Create" : "Update" }}
				</b-button>
				<b-button @click="onCancel"> Cancel </b-button>
			</div>
		</b-form>

		<!-- Modal to add Category -->
		<b-modal v-model="showCreateCategoryModal" centered hide-header hide-footer>
			<MerchantCategoryForm @success="onCategoryCreationSuccess" @cancel="onCategoryCreationCancel" />
		</b-modal>
	</b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { addProduct, updateProduct, getCategories, addProductImage, deleteProductImage } from "../../../apis/merchant";

import MerchantGlobalProductImages from "../../../components/products/merchant/MerchantGlobalProductImages";
import MerchantProductImages from "../../../components/products/merchant/MerchantProductImages";
import MerchantCategoryForm from "../../../components/categories/MerchantCategoryForm";

export default {
	components: {
		MerchantGlobalProductImages,
		MerchantProductImages,
		MerchantCategoryForm,
	},
	created() {
		// setting categories
		getCategories(this.token).then((data) => {
			this.setCategories(data);
		});

		// if is new
		if (this.isNew) {
			// if globalProduct is set, filling form from global product
			const globalProduct = this.globalProduct;
			if (globalProduct) {
				this.form = {
					...this.form,
					name: globalProduct.name,
					unit: globalProduct.unit,
					globalProductId: globalProduct.id,
				};
			}
		}

		// if not new, filling form from product
		if (!this.isNew) {
			const product = this.product;
			this.form = {
				...product,
				categoryId: product.category ? product.category.id : null,
			};
			delete this.form.category;
			delete this.form.globalProductId;

			this.selectedPriceOption = product ? Boolean(product.price) : true;
		}
	},
	data() {
		return {
			globalProduct: this.$route.params.globalProduct,
			productId: this.$route.params.productId,

			// image files uploaded
			imageFiles: [],

			form: {
				name: null,
				categoryId: null,
				price: 1,
				mrp: 1,
				unit: null,
				description: null,
				available: true,

				// if global product used
				globalProductId: null,
			},
			errors: null,

			showCreateCategoryModal: false,

			selectedPriceOption: true,
			priceOptions: [
				{ text: "Fixed", value: true },
				{ text: "Not-Fixed", value: false },
			],
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			categories: "merchant/categories/getList",
			getProduct: "merchant/products/getItem",
			unitOptions: "masters/getUnits",
		}),
		product() {
			if (this.productId) {
				return this.getProduct(this.productId);
			}
			return null;
		},
		isNew() {
			if (this.product) {
				return false;
			}
			return true;
		},
		dbProductPrice() {
			const product = this.product;
			if (product && product.price) return product.price;
			return 1;
		},
	},
	methods: {
		...mapActions({
			addProductToState: "merchant/products/addItem",
			setCategories: "merchant/categories/setList",
			addProductImageToState: "productImages/addProductImage",
			removeProductImageFromState: "productImages/removeProductImage",
		}),
		onImageAdded(file) {
			this.imageFiles.push(file);
		},
		onDeleteImage(image) {
			if (image) {
				const imageId = image.id;
				if (imageId && this.product && this.product.id) {
					const productId = this.product.id;
					// deleting product image from server
					deleteProductImage(productId, imageId, this.token).then(() => {
						this.onImageDeleteSuccess(productId, imageId);
					});
				} else if (!imageId) {
					// delete image from imageFiles
					const index = this.imageFiles.indexOf(image.file);
					if (index !== -1) {
						this.imageFiles.splice(index, 1);
					}
				}
			}
		},
		onSubmit() {
			this.errors = null;
			const form = this.form;
			// if no MRP set, set selling price as MRP
			if (!form.mrp) {
				form.mrp = form.price;
			}

			if (this.isNew) {
				addProduct(form, this.token)
					.then(this.onSuccess)
					.catch(({ data }) => {
						this.onErrors(data);
					});
			} else {
				updateProduct(this.product.id, form, this.token)
					.then(this.onSuccess)
					.catch(({ data }) => {
						this.onErrors(data);
					});
			}
		},
		onSuccess(data) {
			const imagesToBeUploaded = this.imageFiles;

			// removing image reference first
			this.imageFiles = [];

			// add to state, then return to list page
			if (this.isNew) {
				this.productId = data.id;
			}
			this.addProductToState(data);

			// if images were upload, add images to product
			this.uploadImages(imagesToBeUploaded);

			this.$router.replace({ name: "ProductsList" });
		},
		uploadImages(imagesToBeUploaded) {
			if (imagesToBeUploaded) {
				imagesToBeUploaded.forEach((file) => {
					const productImageData = new FormData();
					productImageData.append("productImage", file, file.name);
					addProductImage(this.productId, productImageData, this.token).then(this.onImageUploadSuccess);
				});
			}
		},
		onImageUploadSuccess(data) {
			// adding product image to state
			const src = data.productImage.blobUrl;
			this.addProductImageToState({
				id: this.productId,
				image: { id: data.id, src },
			});
		},
		onImageDeleteSuccess(productId, imageId) {
			this.removeProductImageFromState({ id: productId, imageId });
		},
		onErrors(data) {
			this.errors = data.errors;
		},
		onCancel() {
			this.$router.go(-1);
		},

		// Category
		onCreateCategoryClicked() {
			this.showCreateCategoryModal = true;
		},
		onCategoryCreationSuccess(data) {
			this.showCreateCategoryModal = false;
			this.form.categoryId = data.id;
		},
		onCategoryCreationCancel() {
			this.showCreateCategoryModal = false;
		},

		// Selling Price
		onSelectOption(fixed) {
			if (fixed) this.form.price = this.dbProductPrice;
			else this.form.price = null;
		},

		isNumber: function(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
	},
};
</script>
